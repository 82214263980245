<template>
    <router-link :to="{ name: routerName }"
                 v-slot="{ href, route, navigate, isActive }">
        <b-dropdown-item :active="isActive" :href="href" @click="navigate">
            <slot></slot>
        </b-dropdown-item>
    </router-link>
</template>

<script>
    export default {
        name: "SubMenuItem",
        props:{
            routerName: String,
            redirectFromParent: String
        },
        mounted(){
            if (this.redirectFromParent === this.$route.name){
                this.$router.push({name: this.routerName})
            }
        }
    }
</script>

<style scoped>

</style>
