<template>
  <div>
    <div class="row mb-3">
      <div class="col-auto">
        <add-button router-name="PlatformAdd"></add-button>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <b-table-simple bordered>
          <b-thead>
            <b-tr>
              <b-th>Аббревиатура</b-th>
              <b-th>Название</b-th>
              <b-th>Полное название</b-th>
              <b-th>Команпия</b-th>
              <b-th>Тип</b-th>
              <b-th>Дата выхода</b-th>
              <b-th></b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="generation in generations">
              <tr :key="generation.generationName">
                <td colspan="7"><h5 class="text-center">{{generation.generationName}}</h5></td>
              </tr>
              <tr v-for="platform in generation.platforms" :key="platform.shortName">
                <td>{{platform.shortName}}</td>
                <td>{{platform.name}}</td>
                <td>{{platform.fullName}}</td>
                <td>{{platform.companyName}}</td>
                <td>{{platform.typeName}}</td>
                <td>{{platform.releaseDateFormatted}}</td>
                <td>
                  <div class="buttons">
                    <edit-button router-name="PlatformEdit" :item-id="platform.id"></edit-button>
                    <delete-button modal-name="platform-delete" :item-id="platform.id"
                                   delete-url="admin/platform/delete"
                                   @deleted="getData"></delete-button>
                  </div>
                </td>
              </tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../services/api-service";
import AddButton from "../../components/AddButton";
import EditButton from "../../components/EditButton";
import DeleteButton from "../../components/DeleteButton";

export default {
  name: "PlatformList",
  components: {DeleteButton, EditButton, AddButton},
  data() {
    return {
      generations: [],
    }
  },
  methods: {
    getData() {
      ApiService.get("admin/platform/list").then(response => {
        this.generations = response.data
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>
<style scoped></style>
