<template>
    <div>
        <div class="row mb-3">
            <div class="col-auto">
                <add-button router-name="GenreAdd"></add-button>
            </div>
        </div>
        <div class="row">
            <div class="col-auto">
                <b-table bordered :items="items" :fields="fields">
                    <template #cell(editDelete)="data">
                        <div class="buttons">
                            <edit-button router-name="GenreEdit" :item-id="data.item.id"></edit-button>
                            <delete-button modal-name="genre-delete" :item-id="data.item.id"
                                           delete-url="admin/genre/delete"
                                           @deleted="getData"></delete-button>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../../services/api-service";
    import AddButton from "../../components/AddButton";
    import EditButton from "../../components/EditButton";
    import DeleteButton from "../../components/DeleteButton";

    export default {
        name: "GenreList",
        components: {DeleteButton, EditButton, AddButton},
        data() {
            return {
                items: [],
                fields: [
                    {key: "fullName", label: "Название"},
                    {key: "shortName", label: "Краткое название"},
                    {key: "editDelete", label: ""}
                ]
            }
        },
        methods: {
            getData() {
                ApiService.get("admin/genre/list").then(response => {
                    this.items = response.data
                });
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>
