<template>
  <div class="row">
    <div class="col-xl-4">
      <simple-form back-router-name="GameRegionList"
                   root-url="admin/game-region"
                   v-model="region">
        <b-form-group label-cols="4" label="Краткое название" label-for="short-name">
          <b-form-input id="short-name" v-model="region.shortName" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Полное название" label-for="name">
          <b-form-input id="name" v-model="region.name" required></b-form-input>
        </b-form-group>
      </simple-form>
    </div>
  </div>
</template>

<script>
import SimpleForm from "../../components/SimpleForm";

export default {
  name: "GameRegionEdit",
  components: {SimpleForm},
  data() {
    return {
      region: {
        id: null,
        shortName: null,
        name: null,
      }
    }
  }
}
</script>

<style scoped>

</style>