<template>
    <div></div>
</template>

<script>
    export default {
        name: "Logout",
        mounted() {
            localStorage.removeItem("hasDropmaniaAccess")
            localStorage.removeItem("admin")
            localStorage.removeItem("token")
            this.$router.push({ name: "All"})
        }
    }
</script>

<style scoped>

</style>
