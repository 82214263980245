<template>
  <div class="row">
    <div class="col-xl-4">
      <simple-form back-router-name="PlatformCompanyList"
                   root-url="admin/platform-company"
                   v-model="platformCompany">
        <b-form-group label-cols="4" label="Название" label-for="name">
          <b-form-input id="name" v-model="platformCompany.name" required></b-form-input>
        </b-form-group>
      </simple-form>
    </div>
  </div>
</template>
<script>

import SimpleForm from "@/components/SimpleForm.vue";
export default {
  name: "PlatformCompanyEdit",
  components: {SimpleForm},
  data() {
    return {
      platformCompany: {
        id: null,
        name: null
      }
    }
  }
}
</script>

<style scoped>

</style>