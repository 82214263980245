import SubMenuItem from "../components/SubMenuItem";
import MenuItemDropdown from "../components/MenuItemDropdown";

export default {
    name: "MainMenu",
    components: {SubMenuItem, MenuItemDropdown},
    computed: {
        admin: function () {
            return localStorage.getItem("admin") === "true"
        },
        hasDropmaniaAccess: function () {
            return localStorage.getItem("hasDropmaniaAccess") === "true"
        },
        hasToken: function () {
            return localStorage.getItem("token")
        }
    }
}