import VodIcon from "../../components/VodIcon"
import ApiService from "../../services/api-service"

export default {
    name: "Completed",
    components: {VodIcon},
    data() {
        return {
            loading: false,
            filter: {
                name: null,
                platformId: null,
                genreId: null,
                projectId: null,
                hardwareType: null
            },
            filtersShown: false,
            platforms: [],
            genres: [],
            projects: [],
            hardwareTypes: [],
            allItems: [],
            fields: [
                {key: "index", label: "№", sortable: true},
                {key: "gameName", label: "Название", sortable: true},
                {key: "platformName", label: "Платформа", sortable: true},
                {key: "genreName", label: "Жанр", sortable: true},
                {key: "projectName", label: "Проект", sortable: true},
                {key: "duration", label: "Время", sortable: true},
                {key: "endDate", label: "Пройдено", sortable: true},
                {key: "rate", label: "Рейтинг", sortable: true},
                {key: "difficulty", label: "Сложность", sortable: true},
                {key: "pickedBy", label: "Выбрал", sortable: true},
                {key: "vodParts", label: "Ссылки на воды", tdClass: "vods"}
            ]
        }
    },
    computed: {
        filtersShownLabel: function () {
            return this.filtersShown ? 'Скрыть фильтры' : 'Показать фильтры'
        },
        filteredItems: function () {
            return this.allItems.filter(item => {
                if (this.filter.name) {
                    if (!item.gameName.toLowerCase().includes(this.filter.name.toLowerCase())) {
                        return false
                    }
                }

                if (this.filter.platformId) {
                    if (item.platformId !== this.filter.platformId) {
                        return false
                    }
                }

                if (this.filter.genreId) {
                    if (item.genreId !== this.filter.genreId) {
                        return false
                    }
                }

                if (this.filter.projectId) {
                    if (item.projectId !== this.filter.projectId) {
                        return false
                    }
                }

                if (this.filter.hardwareType){
                    if (item.hardwareType !== this.filter.hardwareType){
                        return false
                    }
                }

                return true
            });
        }
    },
    methods: {
        toggleFiltersVisibility() {
            this.filtersShown = !this.filtersShown
            console.log(this.filtersShown)
        },
        getData() {
            this.loading = true
            ApiService.get("completed/all").then(response => {
                this.allItems = response.data
            }).finally(() => {
                this.loading = false
            })
            ApiService.get("items/platforms").then(response => {
                this.platforms = response.data
            })
            ApiService.get("items/genres").then(response => {
                this.genres = response.data
            })
            ApiService.get("items/projects").then(response => {
                this.projects = response.data
            })
            ApiService.get("items/hardware-types").then(response => {
                this.hardwareTypes = response.data
            })
        }
    },
    mounted() {
        this.getData()
    }
}