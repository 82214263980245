<template>
  <div>
    <div class="row mb-3">
      <div class="col-auto">
        <add-button router-name="PlatformGenerationAdd"></add-button>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <b-table bordered :items="items" :fields="fields">
          <template #cell(year)="data">
            {{data.item.startYear}}<span class="ml-2" v-if="data.item.endYear">- {{data.item.endYear}}</span>
          </template>
          <template #cell(editDelete)="data">
            <div class="buttons">
              <edit-button router-name="PlatformGenerationEdit" :item-id="data.item.id"></edit-button>
              <delete-button modal-name="platform-generation-delete" :item-id="data.item.id"
                             delete-url="admin/platform-generation/delete"
                             @deleted="getData"></delete-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>

import DeleteButton from "@/components/DeleteButton.vue";
import EditButton from "@/components/EditButton.vue";
import AddButton from "@/components/AddButton.vue";
import ApiService from "@/services/api-service";

export default {
  name: "PlatformGenerationList",
  components: {DeleteButton, EditButton, AddButton},
  data() {
    return {
      items: [],
      fields: [
        {key: "name", label: "Поколение"},
        {key: "year", label: "Год"},
        {key: "editDelete", label: ""}
      ]
    }
  },
  methods: {
    getData() {
      ApiService.get("admin/platform-generation/list").then(response => {
        this.items = response.data
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>