<template>
  <div class="row">
    <div class="col-xl-4">
      <simple-form back-router-name="PlatformList"
                   root-url="admin/platform"
                   v-model="platform">
        <b-form-group label-cols="4" label="Краткое название" label-for="short-name">
          <b-form-input id="short-name" v-model="platform.shortName" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Название" label-for="name">
          <b-form-input id="name" v-model="platform.name" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Полное название" label-for="full-name">
          <b-form-input id="full-name" v-model="platform.fullName" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Поколение" label-for="generation-id">
          <b-form-select id="generation-id" v-model="platform.generationId"
                         :options="generations"
                         required>
            <template #first>
              <b-form-select-option :value="null" disabled>Не выбрана</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label-cols="4" label="День релиза" label-for="release-day">
          <b-form-input id="release-day" type="number" :min="0" :max="31" v-model="platform.releaseDay"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Месяц релиза" label-for="release-month">
          <b-form-select id="release-month" v-model="platform.releaseMonth"
                         :options="months">
            <template #first>
              <b-form-select-option :value="null">Не выбрана</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label-cols="4" label="Год релиза" label-for="release-year">
          <b-form-input id="release-year" type="number" :min="1970" v-model="platform.releaseYear" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Компания" label-for="company-id">
          <b-form-select id="company-id" v-model="platform.companyId"
                         :options="companies"
                         required>
            <template #first>
              <b-form-select-option :value="null" disabled>Не выбрана</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label-cols="4" label="Тип" label-for="type">
          <b-form-select id="type" v-model="platform.type"
                         :options="types"
                         required>
            <template #first>
              <b-form-select-option :value="null" disabled>Не выбрана</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </simple-form>
    </div>
  </div>
</template>

<script>
import SimpleForm from "../../components/SimpleForm";
import ApiService from "@/services/api-service";

export default {
  name: "PlatformEdit",
  components: {SimpleForm},
  data() {
    return {
      platform: {
        id: null,
        shortName: null,
        name: null,
        fullName: null,
        releaseDay: null,
        releaseMonth: null,
        releaseYear: null,
        type: null,
        companyId: null,
        generationId: null
      },
      generations: [],
      months: [
        {value: 1, text: "Январь"},
        {value: 2, text: "Февраль"},
        {value: 3, text: "Март"},
        {value: 4, text: "Апрель"},
        {value: 5, text: "Май"},
        {value: 6, text: "Июнь"},
        {value: 7, text: "Июль"},
        {value: 8, text: "Август"},
        {value: 9, text: "Сентябрь"},
        {value: 10, text: "Октябрь"},
        {value: 11, text: "Ноябрь"},
        {value: 12, text: "Декабрь"}
      ],
      companies: [],
      types: []
    }
  },
  mounted(){
    ApiService.get("admin/items/platform-companies")
        .then(response => {
          this.companies = response.data
        })
    ApiService.get("admin/items/platform-generations")
        .then(response => {
          this.generations = response.data
        })
    ApiService.get("admin/items/platform-types")
        .then(response => {
          this.types = response.data
        })
  }
}
</script>
<style scoped></style>
