<template>
    <div class="row">
        <div class="col-xl-4">
            <simple-form back-router-name="ProjectList"
                         root-url="admin/project"
                         v-model="project">
                <b-form-group label-cols="4" label="Название" label-for="name">
                    <b-form-input id="name" v-model="project.name" required></b-form-input>
                </b-form-group>
            </simple-form>
        </div>
    </div>
</template>

<script>
    import SimpleForm from "../../components/SimpleForm";

    export default {
        name: "ProjectEdit",
        components: {SimpleForm},
        data() {
            return {
                project: {
                    id: null,
                    name: null,
                }
            }
        }
    }
</script>

<style scoped>

</style>
