<template>
    <b-nav-item-dropdown :text="text">
        <slot></slot>
    </b-nav-item-dropdown>
</template>

<script>
    export default {
        name: "MenuItemDropdown",
        props: {
            text: String
        }
    }
</script>

<style scoped>

</style>
