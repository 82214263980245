import ApiService from "../../services/api-service"
import AddButton from "../../components/AddButton"
import EditButton from "../../components/EditButton"
import DeleteButton from "../../components/DeleteButton"
import VodIcon from "../../components/VodIcon";

export default {
    name: "PlaythroughList",
    components: {VodIcon, AddButton, EditButton, DeleteButton},
    data() {
        return {
            loading: false,
            items: [],
            fields: [
                {key: "index", label: "№"},
                {key: "gameName", label: "Название"},
                {key: "platformName", label: "Платформа"},
                {key: "genreName", label: "Жанр"},
                {key: "status", label: "Статус"},
                {key: "rate", label: "Рейтинг"},
                {key: "difficulty", label: "Сложность"},
                {key: "region", label: "Регион"},
                {key: "duration", label: "Время"},
                {key: "startDate", label: "Начало"},
                {key: "endDate", label: "Конец"},
                {key: "projectName", label: "Проект"},
                {key: "firstPlaythrough", label: "Первое прохождение"},
                {key: "blind", label: "Без подсказок"},
                {key: "vods", label: "Ссылки на воды", tdClass: "vods"},
                {key: "hardwareTypeName", label: "На чем пройдено"},
                {key: "comment", label: "Комментарий"},
                {key: "editDelete", label: ""},
            ]
        }
    },
    methods: {
        getData() {
            this.loading = true
            ApiService.get("admin/playthrough/list").then(response => {
                this.items = response.data
            }).finally(() => {
                this.loading = false
            })
        }
    },
    mounted() {
        this.getData()
    }
}