import axios from "axios"

export default {
    name: "ApiService",
    host: process.env.VUE_APP_API_HOST_NAME,
    getConfig(url) {
        if (url.startsWith("admin")) {
            return {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }
        } else {
            return {
                headers: {}
            }
        }

    },
    createUrl(url) {
        return this.host + "/api/v1/" + url;
    },
    onError(err){
        console.log(err);
    },
    get(url) {
        return axios.get(this.createUrl(url), this.getConfig(url))
            .catch(this.onError)
    },
    put(url, data) {
        return axios.put(this.createUrl(url), data, this.getConfig(url))
            .catch(this.onError)
    },
    post(url, data){
        return axios.post(this.createUrl(url), data, this.getConfig(url))
            .catch(this.onError)
    },
    delete(url){
        return axios.delete(this.createUrl(url), this.getConfig(url))
            .catch(this.onError)
    }
}
