<template>
    <div>
        <b-row class="mb-3">
            <b-col cols="auto">
                <add-button router-name="GameAdd"></add-button>
            </b-col>
            <b-col cols="auto">
                <b-form-select id="game-platform" v-model="filter.platformId"
                               :options="platforms"
                               required @change="filterUpdated">
                    <template #first>
                        <b-form-select-option :value="null">Не выбрана</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="auto">
                <b-form-checkbox v-model="filter.onlyCompleted" @input="filterUpdated">
                    Только пройденное
                </b-form-checkbox>
            </b-col>
            <b-col cols="auto" v-show="loading">
                <b-spinner small></b-spinner>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="auto">
                <b-table bordered :items="items" :fields="fields">
                    <template #cell(index)="data">
                        {{data.index+1}}
                    </template>
                    <template #cell(editDelete)="data">
                        <div class="buttons">
                            <edit-button router-name="GameEdit" :item-id="data.item.id"></edit-button>
                            <delete-button modal-name="game-delete" :item-id="data.item.id"
                                           delete-url="admin/game/delete"
                                           @deleted="getData"></delete-button>
                        </div>
                    </template>
                </b-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import ApiService from "../../services/api-service";
    import AddButton from "../../components/AddButton";
    import EditButton from "../../components/EditButton";
    import DeleteButton from "../../components/DeleteButton";

    export default {
        name: "GameList",
        components: {DeleteButton, EditButton, AddButton},
        data() {
            return {
                loading: false,
                items: [],
                fields: [
                    {key: "index", label: "№"},
                    {key: "name", label: "Название"},
                    {key: "platformName", label: "Платформа"},
                    {key: "genreName", label: "Жанр"},
                    {key: "rate", label: "Рейтинг"},
                    {key: "difficulty", label: "Сложность"},
                    {key: "editDelete", label: ""}
                ],
                filter: {
                    onlyCompleted: false,
                    platformId: null
                },
                platforms: []
            }
        },
        methods: {
            initFilter() {
                let onlyCompleted = localStorage.getItem("admin.game.only-completed")
                let platformId = localStorage.getItem("admin.game.platform-id")
                if (onlyCompleted) {
                    this.filter.onlyCompleted = onlyCompleted
                }

                if (platformId) {
                    this.filter.platformId = platformId
                }

            },
            getData() {
                this.loading = true
                ApiService.put("admin/game/list", this.filter).then(response => {
                    this.items = response.data
                }).finally(() => {
                    this.loading = false
                })
            },
            filterUpdated() {
                localStorage.setItem("admin.game.only-completed", this.filter.onlyCompleted)
                localStorage.setItem("admin.game.platform-id", this.filter.platformId)
                this.getData()
            }
        },
        mounted() {
            this.initFilter()
            this.getData()
            ApiService.get("admin/items/platforms")
                .then(response => {
                    this.platforms = response.data
                })
        }
    }
</script>

<style scoped>
    .row {
        align-items: center;
    }
</style>