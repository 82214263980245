import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faArrowLeft,
    faFire,
    faFlag,
    faMinus,
    faPen,
    faPlus,
    faStar,
    faTrashAlt,
    faStopwatch,
    faEyeSlash,
    faChessPawn,
    faVideo,
    faFilter,
    faSignOutAlt,
    faCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons'

export default {
    init() {
        library.add(faArrowLeft)
        library.add(faPlus)
        library.add(faMinus)
        library.add(faPen)
        library.add(faTrashAlt)
        library.add(faFire)
        library.add(faStar)
        library.add(faFlag)
        library.add(faStopwatch)
        library.add(faEyeSlash)
        library.add(faChessPawn)
        library.add(faVideo)
        library.add(faFilter)
        library.add(faSignOutAlt)
        library.add(faCheck)
        library.add(faTimes)
    }
}
