<template>
    <div class="row">
        <div class="col-xl-4">
            <simple-form back-router-name="GameList"
                         root-url="admin/game"
                         v-model="game">
                <b-form-group label-cols="4" label="Название" label-for="name">
                    <b-form-input id="name" v-model="game.name" required></b-form-input>
                </b-form-group>
                <b-form-group label-cols="4" label="Платформа" label-for="game-platform">
                    <b-form-select id="game-platform" v-model="game.platformId"
                                   :options="platforms"
                                   required>
                        <template #first>
                            <b-form-select-option :value="null" disabled>Не выбрана</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group label-cols="4" label="Жанр" label-for="game-genre">
                    <b-form-select id="game-genre" v-model="game.genreId"
                                   :options="genres"
                                   required>
                        <template #first>
                            <b-form-select-option :value="null" disabled>Не выбрана</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-form-group label-cols="4" label="Рейтинг" label-for="game-rate">
                    <b-form-input id="game-rate" type="number" v-model="game.rate"></b-form-input>
                </b-form-group>
                <b-form-group label-cols="4" label="Сложность" label-for="game-rating">
                    <b-form-input id="game-difficulty" type="number"
                                  v-model="game.difficulty"></b-form-input>
                </b-form-group>
            </simple-form>
        </div>
    </div>
</template>

<script>
    import SimpleForm from "../../components/SimpleForm";
    import ApiService from "../../services/api-service";

    export default {
        name: "GameEdit",
        components: {SimpleForm},
        data() {
            return {
                game: {
                    id: null,
                    name: null,
                    platformId: null,
                    genreId: null,
                    rate: null,
                    difficulty: null
                },
                platforms: [],
                genres: []
            }
        },
        mounted() {
            ApiService.get("admin/items/platforms")
                .then(response => {
                    this.platforms = response.data
                })
            ApiService.get("admin/items/genres")
                .then(response => {
                    this.genres = response.data
                })
        }
    }
</script>

<style scoped>

</style>
