<template>
  <div>
    <div class="row mb-3">
      <div class="col-auto">
        <add-button router-name="PlatformCompanyAdd"></add-button>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <b-table bordered :items="items" :fields="fields">
          <template #cell(editDelete)="data">
            <div class="buttons">
              <edit-button router-name="PlatformCompanyEdit" :item-id="data.item.id"></edit-button>
              <delete-button modal-name="platform-company-delete" :item-id="data.item.id"
                             delete-url="admin/platform-company/delete"
                             @deleted="getData"></delete-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>

import DeleteButton from "@/components/DeleteButton.vue";
import EditButton from "@/components/EditButton.vue";
import AddButton from "@/components/AddButton.vue";
import ApiService from "@/services/api-service";

export default {
  name: "PlatformCompanyList",
  components: {DeleteButton, EditButton, AddButton},
  data() {
    return {
      items: [],
      fields: [
        {key: "name", label: "Полное название"},
        {key: "editDelete", label: ""}
      ]
    }
  },
  methods: {
    getData() {
      ApiService.get("admin/platform-company/list").then(response => {
        this.items = response.data
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

</style>