import { render, staticRenderFns } from "./PlatformCompany.vue?vue&type=template&id=15911555&scoped=true"
import script from "./PlatformCompany.vue?vue&type=script&lang=js"
export * from "./PlatformCompany.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15911555",
  null
  
)

export default component.exports