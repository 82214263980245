<template>
    <div>
        <main-menu></main-menu>
        <b-row class="m-3">
            <b-col>
                <router-view></router-view>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import MainMenu from "./MainMenu";
    export default {
        name: "All",
        components: {MainMenu}
    }
</script>

<style scoped>

</style>
