<template>
    <div class="container vh-100">
        <div class="d-flex align-items-center justify-content-center vh-100">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-center h-100">
                        <b-form @submit="onSubmit">
                            <b-form-group
                                    id="login-group"
                                    label-cols-sm="4"
                                    label-cols-lg="3"
                                    label="Логин"
                                    label-for="login">
                                <b-form-input id="login" v-model="form.login" required></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="password-group"
                                    label-cols-sm="4"
                                    label-cols-lg="3"
                                    label="Пароль"
                                    label-for="password">
                                <b-form-input id="password" type="password" v-model="form.password" required></b-form-input>
                            </b-form-group>
                            <b-form-group
                                    id="key-group"
                                    label-cols-sm="4"
                                    label-cols-lg="3"
                                    label="Ключ"
                                    label-for="key">
                                <b-form-input id="key" v-model="form.key" required></b-form-input>
                            </b-form-group>
                            <b-row v-if="error" class="my-2">
                                <b-col cols="auto">
                                    <span class="text-danger">Не удалось зарегистрироваться</span>
                                </b-col>
                            </b-row>
                            <b-btn type="submit" variant="primary">
                                Зарегистрироваться
                            </b-btn>
                        </b-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "../services/api-service";

    export default {
        name: "Register",
        data() {
            return {
                form: {
                    login: "",
                    password: "",
                    key: ""
                },
                error: false
            }
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault()
                ApiService.post("auth/register", this.form).then(response => {
                    localStorage.setItem("token", response.data.token)
                    localStorage.setItem("admin", response.data.admin)
                    localStorage.setItem("hasDropmaniaAccess", response.data.hasDropmaniaAccess)
                    this.$router.push({ name: 'All' })
                }).catch(() => {
                    this.error = true
                })
            }
        }
    }
</script>

<style scoped>

</style>
