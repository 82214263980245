<template>
    <span>
        <b-button variant="danger" size="sm" v-b-modal="`${modalName}-${itemId}`" >
            <font-awesome-icon icon="trash-alt"></font-awesome-icon>
        </b-button>
        <b-modal :id="`${modalName}-${itemId}`" title="Удаление записи" @ok="handleOk">
            <p>Удалить запись?</p>
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="danger" @click="ok()">
                    Да
                </b-button>
                <b-button variant="outline-secondary" @click="cancel()">
                    Нет
                </b-button>
            </template>
        </b-modal>
    </span>

</template>

<script>
    import ApiService from "../services/api-service";
    export default {
        name: "DeleteButton",
        props:{
            itemId: Number,
            modalName: String,
            deleteUrl: String
        },
        methods:{
            handleOk(){
                ApiService.delete(`${this.deleteUrl}/${this.itemId}`)
                .then(() => {
                    this.$emit("deleted")
                })
            }
        }
    }
</script>

<style scoped>

</style>
