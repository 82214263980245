<style>
    .vod-icon{
        padding-right: 2px;
    }

    .vod-icon img{
        width: 24px;
        height: 24px;
    }
</style>
<template>
    <a :href="url" target="_blank" class="vod-icon">
        <img :src="fullUrl" :alt="`Часть ${ordinal+1}`" :title="`Часть ${ordinal+1}`">
    </a>
</template>

<script>
    export default {
        name: "VodIcon",
        data: function(){
            return {
                fullUrl: null
            }
        },
        props: {
            url: String,
            type: String,
            ordinal: Number
        },
        mounted(){
            this.fullUrl = require(`@/assets/img/${this.type.toLowerCase()}.png`)
        }
    }
</script>

<style scoped>

</style>
