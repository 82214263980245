<template>
    <router-link :to="{ name: routerName, params: { id: itemId}}" v-slot="{href, route, navigate}">
        <b-button variant="warning" size="sm" @click="navigate">
            <font-awesome-icon icon="pen"></font-awesome-icon>
        </b-button>
    </router-link>
</template>

<script>
    export default {
        name: "EditButton",
        props:{
            routerName: String,
            itemId: Number
        }
    }
</script>

<style scoped>

</style>
