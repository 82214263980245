<template>
    <b-form-input id="id" :value="duration" @blur="duration = $event.target.value" :formatter="durationFormatter"
                  lazy-formatter :required="required"></b-form-input>
</template>

<script>
    export default {
        name: "DurationInput",
        props: {
            id: String,
            required: Boolean,
            value: Number
        },
        model:{
            value: "value",
            event: "change"
        },
        computed: {
            duration: {
                get: function () {
                    if (!this.value){
                        return null
                    }

                    let hours = parseInt(this.value / 3600, 10)
                    let minutes = parseInt((this.value - hours * 3600) / 60, 10)
                    let seconds = parseInt((this.value - hours * 3600 - minutes * 60) % 60, 10)

                    if (minutes < 10){
                        minutes = "0" + minutes
                    }

                    if (seconds < 10){
                        seconds = "0" + seconds
                    }

                    return `${hours}:${minutes}:${seconds}`
                },
                set: function (value) {
                    let v = null
                    if (value){
                        let arr = value.split(":")
                        if (arr.length === 1){
                            v = parseInt(arr[0])
                        } else if (arr.length === 2){
                            v = parseInt(arr[0]) * 60 + parseInt(arr[1])
                        } else{
                            v = parseInt(arr[0]) * 3600 + parseInt(arr[1]) * 60 + parseInt(arr[2])
                        }
                    }

                    console.log(v)
                    this.$emit("change", v)
                }
            }
        },
        methods: {
            durationFormatter(value) {
                if (!value) {
                    return
                }

                let arr = value.split(":")
                let newArr = []

                for (let i = 0; i < arr.length; i++) {
                    let v = arr[i]

                    if (v.length === 1) {
                        v = "0" + v
                    } else if (v.length >= 2) {
                        v = v.substr(0, 2)

                        if ((arr.length === 3
                            && i !== 0) || arr.length < 3) {
                            let number = parseInt(v)

                            if (!number || number > 60) {
                                v = "00"
                            }
                        }
                    }

                    if (v) {
                        newArr[i] = v
                    }
                }

                if (newArr.length === 1) {
                    return `00:00:${newArr[0]}`
                } else if (arr.length === 2) {
                    return `00:${newArr[0]}:${newArr[1]}`
                } else {
                    return `${newArr[0]}:${newArr[1]}:${newArr[2]}`
                }
            }
        }
    }
</script>

<style scoped>

</style>
