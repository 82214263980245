import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import BootstrapVue from "bootstrap-vue"
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import IconService from "./services/icon-service"
import './assets/css/style.css'

const isDev = process.env.NODE_ENV !== "production"

Vue.config.productionTip = false
Vue.config.devtools = isDev
Vue.config.performance = isDev

Vue.use(BootstrapVue)
Vue.use(VueMoment, {
  moment
})
IconService.init()
Vue.component('font-awesome-icon', FontAwesomeIcon)


new Vue({
  router,
  render: h => h(App)
}).$mount("#app")

Vue.filter("duration", function(value){
  if (!value){
    return ""
  }

  let hours = parseInt(value / 3600, 10)
  let minutes = parseInt((value - hours * 3600) / 60, 10)
  let seconds = parseInt((value - hours * 3600 - minutes * 60) % 60, 10)

  if (minutes < 10){
    minutes = "0" + minutes
  }

  if (seconds < 10){
    seconds = "0" + seconds
  }

  return `${hours}:${minutes}:${seconds}`
})