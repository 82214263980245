

<template>
  <div>
    <div class="row mb-3">
      <div class="col-auto">
        <add-button router-name="GameRegionAdd"></add-button>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <b-table bordered :items="items" :fields="fields">
          <template #cell(editDelete)="data">
            <div class="buttons">
              <edit-button router-name="GameRegionEdit" :item-id="data.item.id"></edit-button>
              <delete-button modal-name="game-region-delete" :item-id="data.item.id"
                             delete-url="admin/game-region/delete"
                             @deleted="getData"></delete-button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>

import AddButton from "@/components/AddButton.vue";
import EditButton from "@/components/EditButton.vue";
import DeleteButton from "@/components/DeleteButton.vue";
import ApiService from "@/services/api-service";

export default {
  name: "ProjectList",
  components: {DeleteButton, EditButton, AddButton},
  data() {
    return {
      items: [],
      fields: [
        {
          key: "shortName",
          label: "Краткое название"
        },
        {
          key: "name",
          label: "Название"
        },
        {
          key: "editDelete",
          label: ""
        }
      ]
    }
  },
  methods: {
    getData() {
      ApiService.get("admin/game-region/list").then(response => {
        this.items = response.data
      });
    }
  },
  mounted() {
    this.getData();
  }
}
</script>

<style scoped>

</style>