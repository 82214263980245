<template>
  <div class="row">
    <div class="col-xl-4">
      <simple-form back-router-name="PlatformGenerationList"
                   root-url="admin/platform-generation"
                   v-model="platformGeneration">
        <b-form-group label-cols="4" label="Номер" label-for="number">
          <b-form-input id="number" type="number" min="0" v-model="platformGeneration.number" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Название" label-for="name">
          <b-form-input id="name" v-model="platformGeneration.name" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Начальный год" label-for="start-year">
          <b-form-input id="start-year" type="number" v-model="platformGeneration.startYear" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Конечный год" label-for="end-year">
          <b-form-input id="end-year" type="number" v-model="platformGeneration.endYear"></b-form-input>
        </b-form-group>
      </simple-form>
    </div>
  </div>
</template>

<script>

import SimpleForm from "@/components/SimpleForm.vue";
export default {
  name: "PlatformGenerationEdit",
  components: {SimpleForm},
  data() {
    return {
      platformGeneration: {
        id: null,
        number: null,
        name: null,
        startYear: null,
        endYear: null
      }
    }
  }
}
</script>

<style scoped>

</style>