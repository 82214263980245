<template>
  <div class="platforms">
    <div class="platform" v-for="(platform, index) in platforms" :key="index">
      <div class="platform-name" :class="platform.statusClass">{{ platform.shortName }}</div>
      <div class="platform-extra" v-if="platform.showPlusGames">
        <div :style="{visibility : platform.gameCount > 1 ? 'visible' : 'hidden'}" class="platform-plus-games">
          +{{ platform.gameCount - 1 }}
        </div>
        <div :style="{visibility: platform.extraGameCount > 0 ? 'visible' : 'hidden'}" class="platform-extra-games">
          +{{ platform.extraGameCount }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api-service";

export default {
  name: "RggPlatformWidget",
  data: function () {
    return {
      loading: false,
      platforms: []
    }
  },
  props: ["seasonNumber"],
  methods: {
    showPlusGames(platform){
      return platform.gameCount > 1 || platform.extraGameCount > 0
    },
    getPlatformStatusClass(platformStatus) {
      if (platformStatus === "InProgress") {
        return "current"
      }

      if (platformStatus === "Completed") {
        return "completed"
      }

      if (platformStatus === "Waiting") {
        return ""
      }
    },
    loadPlatforms(){
      ApiService.get(`rgg/season/${this.seasonNumber}/platforms`).then(response => {
        this.platforms = response.data.map(platform => {
          platform.statusClass = this.getPlatformStatusClass(platform.status)
          platform.showPlusGames = this.showPlusGames(platform)
          return platform
        })
      }).finally(async () => {
        await new Promise(resolve => setTimeout(resolve, 10000));
        this.loadPlatforms()
      })
    },
  },
  mounted() {
    this.loadPlatforms()
  }
}
</script>

<style scoped>
.platforms {
  background: #000;
  width: 1892px;
  height: 38px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.platform {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.platform-name {
  color: #fff;
  font-size: 18px;
  align-self: center;
}

.platform-extra {
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  font-size: 13px;
  line-height: 14px;
  margin-top: -2px;
}

.platform-plus-games {
  color: #f7bd18;

}

.platform-extra-games {
  color: #71e5ff;
}

.completed {
  color: #626262;
}

.current {
  color: #09cf18;
}
</style>