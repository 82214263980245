<template>
  <div class="row">
    <div class="col-xl-4">
      <simple-form back-router-name="GenreList"
                   root-url="admin/genre"
                   v-model="genre">
        <b-form-group label-cols="4" label="Название" label-for="name">
          <b-form-input id="name" v-model="genre.name" required></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Краткое название" label-for="short-name">
          <b-form-input id="short-name" v-model="genre.shortName"></b-form-input>
        </b-form-group>
        <b-form-group label-cols="4" label="Жанр" label-for="parent-genre">
          <b-form-select id="parent-genre" v-model="genre.parentGenreId" :options="genres">
            <template #first>
              <b-form-select-option :value="null">Не выбран</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </simple-form>
    </div>
  </div>
</template>

<script>
import SimpleForm from "../../components/SimpleForm";
import ApiService from "@/services/api-service";

export default {
  name: "GenreEdit",
  components: {SimpleForm},
  data() {
    return {
      genre: {
        id: null,
        name: null,
        shortName: null,
        parentGenreId: null
      },
      genres: []
    }
  },
  mounted() {
    ApiService.get("admin/items/genres")
        .then(response => {
          this.genres = response.data
        })
  }
}
</script>

<style scoped>

</style>
