<template>
    <b-form @submit="onSubmit">
        <slot></slot>
        <div class="row">
            <div class="col-auto">
                <router-link :to="{ name : backRouterName }" v-slot="{href, route, navigate}">
                    <b-button variant="primary" @click="navigate">
                        <font-awesome-icon icon="arrow-left"></font-awesome-icon>
                        Вернуться
                    </b-button>
                </router-link>
            </div>
            <div class="col-auto">
                <b-button variant="success" type="submit">
                    Сохранить
                </b-button>
            </div>
        </div>
    </b-form>
</template>

<script>
    import ApiService from "../services/api-service"

    export default {
        name: "SimpleForm",
        props: {
            backRouterName: String,
            rootUrl: String,
            value: Object
        },
        methods: {
            onSubmit(event) {
                event.preventDefault();
                let id = this.$route.params.id;
                if (id){
                    ApiService.put(`${this.rootUrl}/update`, this.value)
                        .then(() => {
                            this.$router.push({name: this.backRouterName})
                        })
                } else{
                    ApiService.post(`${this.rootUrl}/create`, this.value)
                    .then(() => {
                        this.$router.push({name: this.backRouterName})
                    })
                }
            }
        },
        mounted() {
            let id = this.$route.params.id;
            if (id) {
                ApiService.get(`${this.rootUrl}/${id}`)
                .then(response => {
                    this.$emit("input", response.data)
                })
            }
        }
    }
</script>

<style scoped>

</style>
