<template>
    <div>
        <b-row>
            <b-col cols="auto">
                <h3>Статистика</h3>
            </b-col>
            <b-col cols="auto" v-show="loading">
                <b-spinner small></b-spinner>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col>
                <b-card no-body>
                    <b-tabs card>
                        <b-tab title="По платформам" active>
                            <table class="table table-striped table-bordered">
                                <thead>
                                <th>Платформа</th>
                                <th>Пройдено</th>
                                <th colspan="2">Самая короткая игра</th>
                                <th colspan="2">Самая длинная игра</th>
                                <th>Среднее время игры</th>
                                <th>Кол-во часов</th>
                                </thead>
                                <tbody>
                                <tr v-for="platform in statistics.platformStatistics.platforms" :key="platform.platformName">
                                    <td>{{platform.platformName}}</td>
                                    <td>{{platform.completedCount}}</td>
                                    <td>{{platform.shortestGameName}}</td>
                                    <td>{{platform.shortestGameTime | duration}}</td>
                                    <td>{{platform.longestGameName}}</td>
                                    <td>{{platform.longestGameTime | duration}}</td>
                                    <td>{{platform.averageGameTime | duration}}</td>
                                    <td>{{platform.hours | duration}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-if="statistics.platformStatistics.total">
                                    <td class="font-weight-bold">Всего</td>
                                    <td>{{statistics.platformStatistics.total.completedCount}}</td>
                                    <td>{{statistics.platformStatistics.total.shortestGameName}}</td>
                                    <td>{{statistics.platformStatistics.total.shortestGameTime | duration}}</td>
                                    <td>{{statistics.platformStatistics.total.longestGameName}}</td>
                                    <td>{{statistics.platformStatistics.total.longestGameTime | duration}}</td>
                                    <td>{{statistics.platformStatistics.total.averageGameTime | duration}}</td>
                                    <td>{{statistics.platformStatistics.total.hours | duration}}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </b-tab>
                        <b-tab title="По жанрам">
                            <table class="table table-striped table-bordered">
                                <thead>
                                <th>Жанр</th>
                                <th>Пройдено</th>
                                <th colspan="2">Самая короткая игра</th>
                                <th colspan="2">Самая длинная игра</th>
                                <th>Среднее время игры</th>
                                <th>Кол-во часов</th>
                                </thead>
                                <tbody>
                                <tr v-for="genre in statistics.genreStatistics.genres" :key="genre.genreName">
                                    <td>{{genre.genreName}}</td>
                                    <td>{{genre.completedCount}}</td>
                                    <td>{{genre.shortestGameName}}</td>
                                    <td>{{genre.shortestGameTime | duration}}</td>
                                    <td>{{genre.longestGameName}}</td>
                                    <td>{{genre.longestGameTime | duration}}</td>
                                    <td>{{genre.averageGameTime | duration}}</td>
                                    <td>{{genre.hours | duration}}</td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr v-if="statistics.genreStatistics.total">
                                    <td class="font-weight-bold">Всего</td>
                                    <td>{{statistics.genreStatistics.total.completedCount}}</td>
                                    <td>{{statistics.genreStatistics.total.shortestGameName}}</td>
                                    <td>{{statistics.genreStatistics.total.shortestGameTime | duration}}</td>
                                    <td>{{statistics.genreStatistics.total.longestGameName}}</td>
                                    <td>{{statistics.genreStatistics.total.longestGameTime | duration}}</td>
                                    <td>{{statistics.genreStatistics.total.averageGameTime | duration}}</td>
                                    <td>{{statistics.genreStatistics.total.hours | duration}}</td>
                                </tr>
                                </tfoot>
                            </table>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import ApiService from "../../services/api-service";

    export default {
        name: "Statistics",
        data() {
            return {
                loading: false,
                statistics: {
                    platformStatistics: {
                        platforms: [],
                        total: null
                    },
                    genreStatistics: {
                        genres: [],
                        total: null
                    },
                }
            }
        },
        methods: {
            getData() {
                this.loading = true
                ApiService.get("statistics/all").then(response => {
                    this.statistics = response.data
                }).finally(() => {
                    this.loading = false
                })
            }
        },
        mounted() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>
