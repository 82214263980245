<template>
    <router-link :to="{ name: routerName }" v-slot="{href, route, navigate}">
        <b-button variant="primary" @click="navigate">
            <font-awesome-icon icon="plus"></font-awesome-icon>
            Добавить
        </b-button>
    </router-link>
</template>

<script>
    export default {
        name: "AddButton",
        props: {
            routerName: String
        }
    }
</script>

<style scoped>

</style>
